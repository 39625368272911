import Amplify, { Storage } from "aws-amplify";
import React from "react";

import { contentToHtml, detailsToHtml } from "./utils/generateSections";
import { withGAuth, LogOut } from "./login";

import "./assets/App.scss";
import amplifySetup from "./amplify-settings.json";

Amplify.configure(amplifySetup);

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      projects: [],
      content: "",
      currentProject: "",
      loading: false,
      searchPhrase: ""
    };
  }

  componentDidMount() {
    Storage.list("roadmaps/")
      .then(result =>
        this.setState(
          {
            projects: result
              .filter(e => e.size > 0 && e.key.includes("index.md"))
              .map(e => e.key.split("/")[1])
              .filter(e => e && e.length > 0)
          },
          () => this.setCurrentProject()
        )
      )
      .catch(err => console.log(err));
  }

  setCurrentProject(key) {
    const { projects } = this.state;
    console.log(window.location.href.split("/")[3]);
    const currentURI = window.location.href.split("/")[3];

    const currentProject = key ? key : currentURI ? currentURI : projects[0];

    window.history.pushState(
      {},
      currentProject.replace("_", " "),
      "/" + currentProject
    );

    this.setState({ loading: true, searchPhrase: "" });

    Storage.get(`roadmaps/${currentProject}/index.md`, { download: true })
      .then(eRoadmap => {
        this.setState(
          {
            content: String.fromCharCode.apply(null, eRoadmap.Body),
            currentProject
          },
          () => {
            Storage.get(`roadmaps/${currentProject}/details.md`, {
              download: true
            })
              .then(eDetails => {
                this.setState({
                  details: detailsToHtml(
                    String.fromCharCode.apply(null, eDetails.Body)
                  ),
                  loading: false
                });
              })
              .catch(e => this.setState({ details: "", loading: false }));
          }
        );
      })
      .catch(e => console.log(e));
  }

  sanitizeHTML(key = "content", shipped = false, searchText = "") {
    return key === "content"
      ? { __html: contentToHtml(this.state.content, shipped, searchText) }
      : { __html: this.state[key] };
  }

  searchChange = e => {
    this.setState({ searchPhrase: e.target.value });
  };

  render() {
    const {
      projects,
      currentProject,
      loading,
      details = "",
      searchPhrase
    } = this.state;
    return (
      <div className="App">
        <header>
          <img src="logo.png" alt="" />
          <LogOut />
        </header>
        <div className="mainContainer">
          <div className="intro">
            <h1>Nordcloud Roadmaps</h1>
            <nav>
              <ul>
                {projects.map(project => (
                  <li
                    className={project === currentProject ? "active" : ""}
                    onClick={() => this.setCurrentProject(project)}
                    key={project.replace("_", " ")}
                  >
                    {project.replace("_", " ").toUpperCase()}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          {loading ? (
            <div className="spinner" />
          ) : (
            <React.Fragment>
              <div className="details">
                {details.length > 0 && (
                  <div
                    dangerouslySetInnerHTML={this.sanitizeHTML("details")}
                    className="detailsContent"
                  />
                )}
              </div>
              <input
                type="text"
                placeholder="🔍 Search in features"
                className="searchbar"
                value={searchPhrase}
                onChange={this.searchChange}
              />
              <div className="roadmap">
                <div
                  dangerouslySetInnerHTML={this.sanitizeHTML(
                    "content",
                    false,
                    searchPhrase
                  )}
                />

                <div
                  dangerouslySetInnerHTML={this.sanitizeHTML(
                    "content",
                    true,
                    searchPhrase
                  )}
                />
              </div>
            </React.Fragment>
          )}
        </div>
        <footer>
          made in poznan with{" "}
          <span role="img" aria-label="love">
            ❤️
          </span>
          ️
        </footer>
      </div>
    );
  }
}

export default withGAuth(App);
