import React from "react";
import { Auth } from "aws-amplify";
import GoogleLogin from "react-google-login";
import settings from "../google-settings.json";

export const withGAuth = App =>
  class extends React.Component {
    constructor() {
      super();
      this.state = { auth: false };
    }

    componentDidMount() {
      Auth.currentAuthenticatedUser()
        .then(user => this.setState({ auth: true }))
        .catch(err => console.log(err));
    }

    googleSuccess = response => {
      const creds = {
        token: response.Zi.id_token,
        expires_at: response.Zi.expires_at
      };
      const user = {
        name: response.profileObj.name
      };
      Auth.federatedSignIn("google", creds, user)
        .then(() => {
          this.setState({ auth: true });
        })
        .catch(error => console.log(error));
    };

    render() {
      const { auth } = this.state;
      return auth ? (
        <App />
      ) : (
        <div className="App">
          <header>
            <img src="logo.png" alt="" />
          </header>
          <div className="loginBox">
            <h1>Welcome,</h1>
            <p>Please sign-in to contiune</p>
            <GoogleLogin
              clientId={settings.clientId}
              buttonText="Sign-in using Google"
              onSuccess={this.googleSuccess}
              onFailure={console.log}
            />
          </div>
        </div>
      );
    }
  };

export const LogOut = () => {
  const logout = () => {
    Auth.signOut()
      .then(data => {
        window.location.reload();
      })
      .catch(err => console.log(err));
  };
  return <button onClick={logout}>Logout</button>;
};
