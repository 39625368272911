import remarkable from "remarkable";

const md = new remarkable("full");

const category = shipped => el =>
  shipped
    ? el.toLowerCase().includes("shipped")
    : !el.toLowerCase().includes("shipped");

const search = searchText => el =>
  el.toLowerCase().includes(searchText.toLowerCase());

const hrToSections = (htmlString, shipped, searchText, imagesCatUrl) =>
  htmlString
    .split("<hr>")
    .filter(el => el.length > 1)
    .filter(category(shipped))
    .filter(search(searchText))
    .reduce(
      (content, section, idx, src) =>
        src.length > 0
          ? content +
            section +
            "</section>" +
            (idx < src.length - 1 ? `<section><input type="checkbox" /> ` : "")
          : "",
      `<section><input type="checkbox" />`
    );

export const contentToHtml = (mdContent, shipped = false, searchText = "") => {
  const convertedMd = hrToSections(md.render(mdContent), shipped, searchText);
  const header = `<h1 class="subHeader">${
    !shipped ? "Coming soon" : "Shipped"
  }</h1>`;
  return convertedMd === `<section><input type="checkbox" />`
    ? searchText.length > 0
      ? `${header}<p class="noResults">No matching results</p>`
      : ""
    : `${header}${convertedMd}`;
};

export const detailsToHtml = mdContent => md.render(mdContent);
